import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { usePartialBasicInformation } from "../../../hooks/usePartialBasicInformation";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeBasicHealthAndWellness } from "../../../lib/storeFunctions";
import { Logo } from "../../../components/Logo";
import {
  HEIGHTSDropdown,
  HEIGHTSEnum,
  WEIGHTSDropdown,
  WEIGHTSEnum,
} from "../../../lib/healthAndWellness";
import { NextButton } from "../../../components/NextButton";
import styles from "./HealthAndWellness.module.css";

const ZHealthWellness = z.object({
  weight: z.enum(WEIGHTSEnum),
  height: z.enum(HEIGHTSEnum),
  activityLevel: z.enum([
    "Sedentary",
    "Lightly Active",
    "Moderate",
    "Very Active",
  ]),
});

export const HealthAndWellness = () => {
  const navigate = useNavigate();
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [activityLevel, setActivityLevel] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const partialInformation = usePartialBasicInformation();
  if (!partialInformation) {
    return <div>Complete basic information expected</div>;
  }

  const handleFieldChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const { name, value } = event.target;
    if (name === "weight") return setWeight(value);
    if (name === "height") return setHeight(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleHabits: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const {
      name,
      dataset: { value },
    } = event.currentTarget;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }
    if (name === "activityLevel") return setActivityLevel(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zHealthWellness = ZHealthWellness.safeParse({
      weight,
      height,
      activityLevel,
    });
    if (!zHealthWellness.success) {
      setShowErrors(true);
      return;
    }

    storeBasicHealthAndWellness(zHealthWellness.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("HealthAndWellness"));
  };

  const zHealthWellness = ZHealthWellness.safeParse({
    weight,
    height,
    activityLevel,
  });
  const weightErrors = getErrors(zHealthWellness, ["weight"]);
  const heightErrors = getErrors(zHealthWellness, ["height"]);
  const activityLevelErrors = getErrors(zHealthWellness, ["activityLevel"]);

  return (
    <section className={styles.healthAndWellnessContainer}>
      <Logo />
      <div className={styles.healthAndWellnessSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.healthAndWellnessForm}
          id="health-and-wellness-info"
        >
          <h2>Health and Wellness</h2>
          <div className={styles.healthAndWellnessInputContainer}>
            <select name="weight" onChange={handleFieldChange} required>
              {WEIGHTSDropdown.map((weight) =>
                weight === WEIGHTSDropdown[0] ? (
                  <option key={weight} value={weight} disabled selected>
                    {weight}
                  </option>
                ) : (
                  <option key={weight} value={weight}>
                    {weight}
                  </option>
                ),
              )}
            </select>
            <Errors show={showErrors} errors={weightErrors} />
          </div>
          <div className={styles.healthAndWellnessInputContainer}>
            <select name="height" onChange={handleFieldChange} required>
              {HEIGHTSDropdown.map((height) =>
                height === HEIGHTSDropdown[0] ? (
                  <option key={height} value={height} disabled selected>
                    {height}
                  </option>
                ) : (
                  <option key={height} value={height}>
                    {height}
                  </option>
                ),
              )}
            </select>
            <Errors show={showErrors} errors={heightErrors} />
          </div>
          <div className={styles.healthAndWellnessFormQuestion}>
            <p>How would you rate your physical activity level?</p>
            <div className={styles.healthAndWellnessFormButtons}>
              <button
                name="activityLevel"
                data-value="Sedentary"
                onClick={handleHabits}
                className={
                  activityLevel === "Sedentary"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Sedentary
              </button>
              <button
                name="activityLevel"
                data-value="Lightly Active"
                onClick={handleHabits}
                className={
                  activityLevel === "Lightly Active"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Lightly Active
              </button>
            </div>
            <div className={styles.healthAndWellnessFormButtons}>
              <button
                name="activityLevel"
                data-value="Moderate"
                onClick={handleHabits}
                className={
                  activityLevel === "Moderate"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Moderate
              </button>
              <button
                name="activityLevel"
                data-value="Very Active"
                onClick={handleHabits}
                className={
                  activityLevel === "Very Active"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Very Active
              </button>
            </div>
            <Errors show={showErrors} errors={activityLevelErrors} />
          </div>
        </form>
        <NextButton form={"health-and-wellness-info"} />
      </div>
    </section>
  );
};
