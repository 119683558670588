import { FormEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { useBasicInformation } from "../../../hooks/useBasicInformation";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./Thanks.module.css";

export const Thanks = () => {
  const navigate = useNavigate();
  const completeBasicInformation = useBasicInformation();
  if (!completeBasicInformation) {
    return <div>Complete basic information expected</div>;
  }

  const [, basicInformation] = completeBasicInformation;
  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    navigate(nextBasicInformationScreen("Thanks"));
  };

  return (
    <section className={styles.thanksContainer}>
      <Logo />
      <div className={styles.thanksSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.thanksForm}
          id="thanks-form"
        >
          <p>
            Thanks for chatting with me today, {basicInformation.firstName}.
            It's been awesome getting to know you better. If you ever need
            anything, I'm just a tap away. Take care!
          </p>
        </form>
        <NextButton form={"thanks-form"} />
      </div>
    </section>
  );
};
