import { FormEventHandler, MouseEventHandler, useState } from "react";
import { z } from "zod";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeComplexHealth } from "../../../lib/storeFunctions";
import { useNavigate } from "react-router-dom";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./ComplexHealth.module.css";

const ZComplexHealth = z.object({
  checkUpsFrequency: z.enum([
    "More than once a month",
    "Monthly",
    "Every 3 months",
    "Twice a year",
    "Annually",
    "Less Often",
  ]),
});

export const ComplexHealth = () => {
  const navigate = useNavigate();
  const [checkUpsFrequency, setCheckUpsFrequency] = useState("");

  const [showErrors, setShowErrors] = useState(false);

  const handleClicks: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const {
      name,
      dataset: { value },
    } = event.currentTarget;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }

    if (name === "checkUpsFrequency") return setCheckUpsFrequency(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zComplexHealth = ZComplexHealth.safeParse({
      checkUpsFrequency,
    });

    if (!zComplexHealth.success) {
      setShowErrors(true);
      return;
    }

    storeComplexHealth({
      checkUpsFrequency,
    });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("ComplexHealth"));
  };

  const zComplexHealth = ZComplexHealth.safeParse({
    checkUpsFrequency,
  });
  const checkUpsFrequencyErrors = getErrors(zComplexHealth, [
    "checkUpsFrequency",
  ]);

  return (
    <section className={styles.complexHealthContainer}>
      <Logo />
      <div className={styles.complexHealthSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.complexHealthForm}
          id="complex-health-form"
        >
          <div className={styles.complexHealthTitle}>
            <h2>Complex Health</h2>
            <p>
              How often do you visit your healthcare provider for check-ups?
            </p>
          </div>
          <div className={styles.complexHealthQuestionsContainer}>
            <div className={styles.complexHealthQuestionContainer}>
              <button
                name="checkUpsFrequency"
                data-value="More than once a month"
                onClick={handleClicks}
                className={
                  checkUpsFrequency === "More than once a month"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Over monthly
              </button>
              <button
                name="checkUpsFrequency"
                data-value="Monthly"
                onClick={handleClicks}
                className={
                  checkUpsFrequency === "Monthly"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Monthly
              </button>
            </div>
            <div className={styles.complexHealthQuestionContainer}>
              <button
                name="checkUpsFrequency"
                data-value="Every 3 months"
                onClick={handleClicks}
                className={
                  checkUpsFrequency === "Every 3 months"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Quarterly
              </button>
              <button
                name="checkUpsFrequency"
                data-value="Twice a year"
                onClick={handleClicks}
                className={
                  checkUpsFrequency === "Twice a year"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Twice a year
              </button>
            </div>
            <div className={styles.complexHealthQuestionContainer}>
              <button
                name="checkUpsFrequency"
                data-value="Annually"
                onClick={handleClicks}
                className={
                  checkUpsFrequency === "Annually"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Annually
              </button>
              <button
                name="checkUpsFrequency"
                data-value="Less Often"
                onClick={handleClicks}
                className={
                  checkUpsFrequency === "Less Often"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Less Often
              </button>
            </div>
            <Errors show={showErrors} errors={checkUpsFrequencyErrors} />
          </div>
        </form>
        <NextButton form={"complex-health-form"} />
      </div>
    </section>
  );
};
