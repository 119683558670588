import styles from "./ConfirmationModal.module.css";

export const ConfirmationModal = ({
  setShowModal,
}: {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <section className={styles.modalContainer}>
      <a onClick={() => setShowModal(false)}>×</a>
      <p>Changes saved</p>
    </section>
  );
};
