import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import { z } from "zod";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { useNavigate } from "react-router-dom";

import { storeMedications } from "../../../lib/storeFunctions";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./Medications.module.css";

const ZMedications = z.object({
  taking: z.boolean(),
  medications: z.string().min(2).trim().optional(),
});

export const Medications = () => {
  const navigate = useNavigate();
  const [taking, setTaking] = useState(null as boolean | null);
  const [medications, setMedications] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const handleFieldChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    const { name, value } = event.target;
    if (name === "medications") return setMedications(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleClicks: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const {
      name,
      dataset: { value },
    } = event.currentTarget;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }

    if (name === "taking") {
      const newTaking = value === "true";
      if (!newTaking) setMedications("");
      setTaking(newTaking);
    }

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zMedications = ZMedications.safeParse({
      taking,
      medications: taking ? medications : undefined,
    });

    if (!zMedications.success) {
      setShowErrors(true);
      return;
    }

    const rawMedications = zMedications.data.medications;

    storeMedications({
      medications: [
        ...new Set(
          rawMedications ? rawMedications.split(",").map((s) => s.trim()) : [],
        ),
      ],
    });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("Medications"));
  };

  const zMedications = ZMedications.safeParse({
    taking,
    medications: taking ? medications : undefined,
  });

  const medicationsErrors = getErrors(zMedications, ["medications"]);
  const takingErrors = getErrors(zMedications, ["taking"]);

  return (
    <section className={styles.medicationsContainer}>
      <Logo />
      <div className={styles.medicationsSecondContainer}>
        <form
          onSubmit={handleSubmit}
          id="medications-form"
          className={styles.medicationsForm}
        >
          <div className={styles.medicationsTitle}>
            <h2>Complex Health</h2>
            <p>Are you currently taking any medication(s)?</p>
          </div>
          <div className={styles.medicationsQuestionsContainer}>
            <div className={styles.medicationsQuestionContainer}>
              <button
                name="taking"
                data-value="true"
                onClick={handleClicks}
                className={taking ? styles.selected : styles.notSelected}
              >
                Yes
              </button>
              <button
                name="taking"
                data-value="false"
                onClick={handleClicks}
                className={
                  taking === false ? styles.selected : styles.notSelected
                }
              >
                No
              </button>
            </div>
            <Errors show={showErrors} errors={takingErrors} />
            <div className={styles.medicationsFormTextarea}>
              <label htmlFor="medications">If yes, please specify:</label>
              <textarea
                disabled={!taking}
                name="medications"
                id="medications"
                value={medications}
                onChange={handleFieldChange}
                placeholder="Write over here..."
              />
              <Errors show={showErrors} errors={medicationsErrors} />
            </div>
          </div>
        </form>
        <NextButton form={"medications-form"} />
      </div>
    </section>
  );
};
