import { getPartialBasicInformation } from "./basicInformation";
import { readState, storeState } from "./storedState";

export const storeSymptoms = (symptoms: string[]): void => {
  const storedState = readState();
  const pbi = getPartialBasicInformation(storedState);
  if (!pbi) return console.warn("User expected to be signed-in");

  storeState({
    ...storedState,
    symptoms: [...storedState.symptoms, ...symptoms],
  });
};
