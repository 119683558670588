import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeHealthGoals } from "../../../lib/storeFunctions";
import { HealthGoalsType } from "../../../lib/basicInformation";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./HealthGoals.module.css";

const POSSIBLE_GOALS = [
  {
    name: "Energy Booster: ",
    description:
      "Feeling tired throughout the day? We can help you find ways to feel your best with better nutrition and more activity!",
  },
  {
    name: "Stress Less: ",
    description:
      "Feeling overwhelmed? We offer tools to manage stress and improve your well-being.",
  },
  {
    name: "Move with More Fun: ",
    description:
      "Staying active shouldn't be a chore! Find activities you enjoy and reach your fitness goals.",
  },
  {
    name: "Preventative Health: ",
    description:
      "Live a healthier life and reduce your risk of chronic illness.",
  },
] as const;

const ZHealthGoals = z.object({
  healthGoals: z
    .array(
      z.object({
        name: z.string(),
        description: z.string(),
      }),
    )
    .nonempty(),
});

export const HealthGoals = () => {
  const navigate = useNavigate();
  const [healthGoals, setHealthGoals] = useState<HealthGoalsType[]>([]);
  const [showErrors, setShowErrors] = useState(false);

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValues = new Set(healthGoals);
    const { id, checked } = event.target;
    const findGoal = POSSIBLE_GOALS.find(
      (goal: HealthGoalsType) => goal.name === id,
    );
    if (findGoal) {
      if (checked) {
        currentValues.add({
          name: findGoal.name,
          description: findGoal.description,
        });
      } else {
        currentValues.delete(findGoal);
      }
      setHealthGoals([...currentValues]);
    }
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zHealthGoals = ZHealthGoals.safeParse({
      healthGoals,
    });
    if (!zHealthGoals.success) {
      setShowErrors(true);
      return;
    }

    const cleanSet = new Set(zHealthGoals.data.healthGoals);
    storeHealthGoals({
      healthGoals: [...cleanSet],
    });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("HealthGoals"));
  };

  const zHealthGoals = ZHealthGoals.safeParse({
    healthGoals,
  });
  const healthGoalsErrors = getErrors(zHealthGoals, ["healthGoals"]);
  return (
    <section className={styles.healthGoalsContainer}>
      <Logo />
      <div className={styles.healthGoalsSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.healthGoalsForm}
          id="health-goals-form"
        >
          <h2>What's your focus for a healthier you?</h2>
          <div className={styles.healthGoalsCheckboxes}>
            {POSSIBLE_GOALS.map((goal) => (
              <div key={goal.name} className={styles.healthGoalsCheckbox}>
                <input
                  type="checkbox"
                  id={goal.name}
                  name="healthGoals"
                  value={goal.name}
                  checked={Boolean(
                    healthGoals.find((g) => g.name === goal.name),
                  )}
                  onChange={handleFieldChange}
                />
                <label htmlFor={goal.name}>
                  <span className={styles.healthGoalsOptionName}>
                    {goal.name}
                  </span>
                  <span>{goal.description}</span>
                </label>
              </div>
            ))}
          </div>
          <Errors show={showErrors} errors={healthGoalsErrors} />
        </form>
        <NextButton form={"health-goals-form"} />
      </div>
    </section>
  );
};
