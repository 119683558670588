import { FormEventHandler, MouseEventHandler, useState } from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeHabits } from "../../../lib/storeFunctions";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./Habits.module.css";

const ZHabits = z.object({
  stressLevel: z.enum(["Low", "Moderate", "High"]),
  smoke: z.boolean(),
  alcohol: z.enum(["Never", "Occasionally", "Socially", "Regularly"]),
});

export const Habits = () => {
  const navigate = useNavigate();
  const [stressLevel, setStressLevel] = useState("");
  const [smoke, setSmoke] = useState(null as boolean | null);
  const [alcohol, setAlcohol] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleHabits: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const {
      name,
      dataset: { value },
    } = event.currentTarget;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }
    if (name === "stressLevel") return setStressLevel(value);
    if (name === "smoke") return setSmoke(value === "true");
    if (name === "alcohol") return setAlcohol(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zHabits = ZHabits.safeParse({
      stressLevel,
      smoke,
      alcohol,
    });

    if (!zHabits.success) {
      setShowErrors(true);
      return;
    }

    storeHabits(zHabits.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("Habits"));
  };

  const zHabits = ZHabits.safeParse({
    stressLevel,
    smoke,
    alcohol,
  });
  const stressLevelErrors = getErrors(zHabits, ["stressLevel"]);
  const smokeErrors = getErrors(zHabits, ["smoke"]);
  const alcoholErrors = getErrors(zHabits, ["alcohol"]);

  return (
    <section className={styles.habitsContainer}>
      <Logo />
      <div className={styles.habitsSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.habitsForm}
          id="habits-form"
        >
          <h2>Lifestyle Habits</h2>
          <div className={styles.habitsFormQuestion}>
            <p>How would you rate your stress levels on average?</p>
            <div className={styles.habitsFormButtons}>
              <button
                name="stressLevel"
                data-value="Low"
                onClick={handleHabits}
                className={
                  stressLevel === "Low" ? styles.selected : styles.notSelected
                }
              >
                Low
              </button>
              <button
                name="stressLevel"
                data-value="Moderate"
                onClick={handleHabits}
                className={
                  stressLevel === "Moderate"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Moderate
              </button>
            </div>
            <div className={styles.habitsFormButtons}>
              <button
                name="stressLevel"
                data-value="High"
                onClick={handleHabits}
                className={
                  stressLevel === "High" ? styles.selected : styles.notSelected
                }
              >
                High
              </button>
              <button className={styles.hiddenButton}></button>
            </div>
            <Errors show={showErrors} errors={stressLevelErrors} />
          </div>
          <div className={styles.habitsFormQuestion}>
            <p>Do you smoke?</p>
            <div className={styles.habitsFormButtons}>
              <button
                name="smoke"
                data-value="true"
                onClick={handleHabits}
                className={smoke ? styles.selected : styles.notSelected}
              >
                Yes
              </button>
              <button
                name="smoke"
                data-value="false"
                onClick={handleHabits}
                className={
                  smoke === false ? styles.selected : styles.notSelected
                }
              >
                No
              </button>
            </div>
            <Errors show={showErrors} errors={smokeErrors} />
          </div>
          <div className={styles.habitsFormQuestion}>
            <p>How often do you drink alcohol?</p>
            <div className={styles.habitsFormButtons}>
              <button
                name="alcohol"
                data-value="Never"
                onClick={handleHabits}
                className={
                  alcohol === "Never" ? styles.selected : styles.notSelected
                }
              >
                Never
              </button>
              <button
                name="alcohol"
                data-value="Occasionally"
                onClick={handleHabits}
                className={
                  alcohol === "Occasionally"
                    ? styles.selected
                    : styles.notSelected
                }
              >
                Occasionally
              </button>
            </div>
            <div className={styles.habitsFormButtons}>
              <button
                name="alcohol"
                data-value="Socially"
                onClick={handleHabits}
                className={
                  alcohol === "Socially" ? styles.selected : styles.notSelected
                }
              >
                Socially
              </button>
              <button
                name="alcohol"
                data-value="Regularly"
                onClick={handleHabits}
                className={
                  alcohol === "Regularly" ? styles.selected : styles.notSelected
                }
              >
                Regularly
              </button>
            </div>
            <Errors show={showErrors} errors={alcoholErrors} />
          </div>
        </form>
        <NextButton form={"habits-form"} />
      </div>
    </section>
  );
};
