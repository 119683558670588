import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeTellCoachGoals } from "../../../lib/storeFunctions";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./TellCoachGoals.module.css";

const ZTellCoach = z.object({
  tellCoachGoals: z.string().optional(),
});

export const TellCoachGoals = () => {
  const navigate = useNavigate();
  const [tellCoachGoals, setTellCoachGoals] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleFieldChangeText: ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    const { name, value } = event.target;
    if (name === "tellCoachGoals") return setTellCoachGoals(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zTellCoach = ZTellCoach.safeParse({
      tellCoachGoals,
    });
    if (!zTellCoach.success) {
      setShowErrors(true);
      return;
    }

    storeTellCoachGoals({
      tellCoachGoals: tellCoachGoals ? tellCoachGoals : "",
    });
    setShowErrors(false);
    navigate(nextBasicInformationScreen("TellCoachGoals"));
  };

  const zTellCoach = ZTellCoach.safeParse({
    tellCoachGoals,
  });
  const tellCoachGoalsErrors = getErrors(zTellCoach, ["tellCoachGoals"]);

  return (
    <section className={styles.tellCoachGoalsContainer}>
      <Logo />
      <div className={styles.tellCoachGoalsSecondContainer}>
        <form
          onSubmit={handleSubmit}
          id="tell-coach-form"
          className={styles.tellCoachGoalsForm}
        >
          <label htmlFor="tell-coach">
            Is there anything else you'd like to tell Coach360 about your health
            goals or challenges?
          </label>
          <textarea
            name="tellCoachGoals"
            id="tell-coach"
            value={tellCoachGoals}
            onChange={handleFieldChangeText}
            placeholder="Write over here..."
          />
          <Errors show={showErrors} errors={tellCoachGoalsErrors} />
        </form>
        <NextButton form={"tell-coach-form"} />
      </div>
    </section>
  );
};
