import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { z } from "zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { useNavigate } from "react-router-dom";
import { getErrors } from "../../../lib/zod";
import { Errors } from "../../../components/Errors";
import { storeNames } from "../../../lib/storeFunctions";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./Names.module.css";

const ZNames = z.object({
  firstName: z.string().min(2).trim(),
  lastName: z.string().min(2).trim(),
});

export const Names = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleFieldChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    if (name !== "firstName" && name !== "lastName") {
      console.log(`Unexpected name: ${name}`);
      return;
    }

    if (name === "firstName") {
      setFirstName(value);
      return;
    }
    if (name === "lastName") {
      setLastName(value);
      return;
    }
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zNames = ZNames.safeParse({ firstName, lastName });
    if (!zNames.success) {
      setShowErrors(true);
      return;
    }

    storeNames(zNames.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("Names"));
  };

  const zNames = ZNames.safeParse({ firstName, lastName });
  const firstNameErrors = getErrors(zNames, ["firstName"]);
  const lastNameErrors = getErrors(zNames, ["lastName"]);
  return (
    <section className={styles.nameContainer}>
      <Logo />
      <div className={styles.nameSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.nameFormContainer}
          id="names-form"
        >
          <div className={styles.nameGreetContainer}>
            <h1>Hey there!</h1>
            <p>
              Welcome to HealthLink360, your personal health companion. I'm here
              to help you understand your health better.
            </p>
            <p>What's your name?</p>
          </div>
          <div className={styles.nameInputContainer}>
            <div className={styles.nameInput}>
              <input
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={handleFieldChange}
                required
              />
              <Errors show={showErrors} errors={firstNameErrors} />
            </div>
            <div className={styles.nameInput}>
              <input
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={handleFieldChange}
                required
              />
              <Errors show={showErrors} errors={lastNameErrors} />
            </div>
          </div>
        </form>
        <NextButton form={"names-form"} />
      </div>
    </section>
  );
};
