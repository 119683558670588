import {
  ChangeEventHandler,
  FormEventHandler,
  MouseEventHandler,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { Errors } from "../../../components/Errors";
import { storeFood } from "../../../lib/storeFunctions";
import { getErrors } from "../../../lib/zod";
import { nextBasicInformationScreen } from "../nextBasicInformationScreen";
import { Logo } from "../../../components/Logo";
import { NextButton } from "../../../components/NextButton";
import styles from "./FoodSensitivities.module.css";

const ZFood = z.object({
  foodSensitivities: z.boolean(),
  foodSensitivitiesDetails: z.string(),
});

export const FoodSensitivities = () => {
  const navigate = useNavigate();
  const [foodSensitivities, setFoodSensitivities] = useState(
    null as boolean | null,
  );
  const [foodSensitivitiesDetails, setFoodSensitivitiesDetails] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const handleSensitivities: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    const { value } = event.currentTarget.dataset;
    if (!value) {
      console.log(
        `data-value is expected to be set for: ${event.currentTarget}`,
      );
      return;
    }
    const newPractice = value === "true";
    if (!newPractice) setFoodSensitivitiesDetails("");
    setFoodSensitivities(newPractice);
  };

  const handleFieldChange: ChangeEventHandler<HTMLTextAreaElement> = (
    event,
  ) => {
    const { name, value } = event.target;
    if (name === "foodSensitivitiesDetails")
      return setFoodSensitivitiesDetails(value);

    console.log(`Unexpected name: ${name}`);
  };

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const zFood = ZFood.safeParse({
      foodSensitivities,
      foodSensitivitiesDetails,
    });

    if (!zFood.success) {
      setShowErrors(true);
      return;
    }

    storeFood(zFood.data);
    setShowErrors(false);
    navigate(nextBasicInformationScreen("FoodSensitivities"));
  };

  const zFood = ZFood.safeParse({
    foodSensitivities,
    foodSensitivitiesDetails,
  });

  const foodSensitivitiesErrors = getErrors(zFood, ["foodSensitivities"]);
  const foodSensitivitiesDetailsErrors = getErrors(zFood, [
    "foodSensitivitiesDetails",
  ]);
  return (
    <section className={styles.foodContainer}>
      <Logo />
      <div className={styles.foodSecondContainer}>
        <form
          onSubmit={handleSubmit}
          className={styles.foodForm}
          id="food-form"
        >
          <h2>Food sensitivities</h2>
          <div className={styles.foodFormQuestion}>
            <p>Any food sensitivities?</p>
            <div className={styles.foodFormButtonsFlex}>
              <button
                data-value="true"
                onClick={handleSensitivities}
                className={
                  foodSensitivities ? styles.selected : styles.notSelected
                }
              >
                Yes
              </button>
              <button
                data-value="false"
                onClick={handleSensitivities}
                className={
                  foodSensitivities === false
                    ? styles.selected
                    : styles.notSelected
                }
              >
                No
              </button>
            </div>
            <Errors show={showErrors} errors={foodSensitivitiesErrors} />
          </div>
          <div className={styles.foodFormQuestion}>
            <p>If yes, please specify:</p>
            <textarea
              name="foodSensitivitiesDetails"
              value={foodSensitivitiesDetails}
              onChange={handleFieldChange}
              disabled={!foodSensitivities}
              placeholder="Write over here"
              required
            />
            <Errors show={showErrors} errors={foodSensitivitiesDetailsErrors} />
          </div>
        </form>
        <NextButton form={"food-form"} />
      </div>
    </section>
  );
};
