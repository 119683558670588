export const HEIGHTSDropdown = [
  `Enter your height`,
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `6'`,
];

export const HEIGHTSEnum = [
  `5'1"`,
  `5'2"`,
  `5'3"`,
  `5'4"`,
  `5'5"`,
  `5'6"`,
  `5'7"`,
  `5'8"`,
  `5'9"`,
  `6'`,
] as const;

export const WEIGHTSDropdown = [
  `Enter your weight`,
  `100 lb`,
  `110 lb`,
  `120 lb`,
  `130 lb`,
  `140 lb`,
  `150 lb`,
  `160 lb`,
  `170 lb`,
  `180 lb`,
  `190 lb`,
  `200 lb`,
  `210 lb`,
  `220 lb`,
  `230 lb`,
  `240 lb`,
  `250 lb`,
  `260 lb`,
  `270 lb`,
  `280 lb`,
];

export const WEIGHTSEnum = [
  `100 lb`,
  `110 lb`,
  `120 lb`,
  `130 lb`,
  `140 lb`,
  `150 lb`,
  `160 lb`,
  `170 lb`,
  `180 lb`,
  `190 lb`,
  `200 lb`,
  `210 lb`,
  `220 lb`,
  `230 lb`,
  `240 lb`,
  `250 lb`,
  `260 lb`,
  `270 lb`,
  `280 lb`,
] as const;
